import {
  FaGithub,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaFacebook,
  FaEnvelope,
  FaLink,
  FaGlobe,
  FaReddit,
  FaQuora,
  FaPodcast,
  FaYoutube,
} from 'react-icons/fa'
import { SiTiktok } from 'react-icons/si'

export default {
  default: {
    Icon: FaLink,
    color: '#718096'
  },
  github: {
    Icon: FaGithub,
    color: 'darkslategray'
  },
  twitter: {
    Icon: FaTwitter,
    color: '#1DA1F2'
  },
  instagram: {
    Icon: FaInstagram,
    color: '#C13584'
  },
  linkedin: {
    Icon: FaLinkedinIn,
    color: '#2867B2'
  },
  facebook: {
    Icon: FaFacebook,
    color: '#4267B2'
  },
  email: {
    Icon: FaEnvelope,
    color: '#718096'
  },
  website: {
    Icon: FaGlobe,
    color: 'darkslategray'
  },
  podcast: {
    Icon: FaPodcast,
    color: '#a1f1f2'
  },
  tiktok: {
    Icon: SiTiktok,
    color: '#ee1052'
  },
  reddit: {
    Icon: FaReddit,
    color: '#ff4301'
  },
  quora: {
    Icon: FaQuora,
    color: '#892b27'
  },
  youtube: {
    Icon: FaYoutube,
    color: '#ff0000'
  },
}
