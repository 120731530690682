import React from 'react'
import { Heading, Flex } from 'theme-ui'
import Navigation from '@components/Navigation'
import useSiteMetadata from '@helpers/useSiteMetadata'
import attachSocialIconsHead from '@helpers/attachSocialIconsHead'

const styles = {
  social: {
    mt: [3,3,3],
    mb: [-2,-3,-4],
    justifyContent: ['center','center','flex-end']
   },
  navHeader: {
    display: [`none`, `block`]
  }
}

export const HeaderSocial = () => {
  const { social } = useSiteMetadata()

  return (
    <>
      <Navigation
        items={attachSocialIconsHead(social)}
        variant={[`horizontal`]}
        wrapperStyle={styles.social}
        iconOnly='true'
      />
    </>
  )
}
