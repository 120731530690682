import {
  FaGithub,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaFacebook,
  FaEnvelope,
  FaLink,
  FaGlobe,
  FaReddit,
  FaQuora,
  FaPodcast,
  FaYoutube,
} from 'react-icons/fa'
import { SiTiktok } from 'react-icons/si'

export default {
  default: {
    Icon: FaLink,
    color: '#cccccc'
  },
  github: {
    Icon: FaGithub,
    color: '#cccccc'
  },
  twitter: {
    Icon: FaTwitter,
    color: '#cccccc'
  },
  instagram: {
    Icon: FaInstagram,
    color: '#cccccc'
  },
  linkedin: {
    Icon: FaLinkedinIn,
    color: '#cccccc'
  },
  facebook: {
    Icon: FaFacebook,
    color: '#cccccc'
  },
  email: {
    Icon: FaEnvelope,
    color: '#cccccc'
  },
  website: {
    Icon: FaGlobe,
    color: '#cccccc'
  },
  podcast: {
    Icon: FaPodcast,
    color: '#cccccc'
  },
  tiktok: {
    Icon: SiTiktok,
    color: '#cccccc'
  },
  reddit: {
    Icon: FaReddit,
    color: '#cccccc'
  },
  quora: {
    Icon: FaQuora,
    color: '#cccccc'
  },
  youtube: {
    Icon: FaYoutube,
    color: '#cccccc'
  },
}
